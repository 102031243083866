import { IGetQuery } from 'ets-fe-ng-sdk';

export interface ISaveCandidate {
  id?: number;
  candidateNo?: string;

  agentAddress?: string;
  agentAddressState?: string;
  birthDt: string;
  company?: string;
  employmentDate?: string;
  externalRef: string;
  firstName: string;
  fullName?: string;
  highestEduLevel: string;
  lastName: string;
  maritalStatus?: string;
  middleName: string;
  nationality?: string;
  nin: string;
  officeEmail: string;
  officePhoneNo: string;
  personalEmail: string;
  personalPhoneNo: string;
  ria?: string;
  stateOfOrigin?: string;
  redgeBasis?: ECandidateRegBasis;

  agentCategory: EAgentCategory;
  approved?: boolean;
}

export interface ICandidate extends ISaveCandidate {
  id: number;
  pin: string;
  candidateNo: string;
  naicomRedg: boolean;
  docKey: string;
  message?: string;
  approved: boolean;
  ciinRedg: boolean;
  certificateIssued: boolean;
  examPass: boolean;

  username: string;
  userPassword?: string;
  examActive: boolean;
  createdOn: string;
  updatedOn: string;
  courseCompleted?: boolean;
  createdBy: string;
  updatedBy: string;
  examPassOn: string;
  pinIssuedOn?: string;
  cifmCerNo: string;
}
export type ICandidateSQ = IGetQuery<{
  fullname: string;
  candidateNo: string;
  agentCategory: string;
  redgeBasis: string;
  examPass: boolean;
  ciinRedg: boolean;
  company: string;
  approved: boolean;
  certificateIssued: boolean;
  naicomRedg: boolean;
  personalEmail: string;
  officeEmail: string;
  pin: string;
  createdFrom: string;
  createdTo: string;
  examPassFrom: string;
  examPassTo: string;
}>;

export enum ECandidateDocument {
  passportPhotograph = 'PP',
  identityCard = 'ID',
  secondarySchoolLeavingCertificate = 'SSC',
  firstDegreeCertificate = 'FDC',
  taxClearance = 'TC',
}

export enum ECandidateRegBasis {
  csr = 'CSR',
  psr = 'PSR',
}
export enum EAgentCategory {
  tiedAgent = 'TA',
  referalAgent = 'RA',
}

export interface ICandidatePinUpdate {
  candidateNo: string;
  pin: string;
}
export interface IValidateCandidatePinUpdate {
  pin: string;
  rowId: string;
}

export interface IValidateCandidate {
  approved: boolean | undefined;
  highestEduLevel: string | undefined;
  nin: string | undefined;
  officeEmail: string | undefined;
  officePhoneNo: string | undefined;
  personalEmail: string | undefined;
  personalPhoneNo: string | undefined;
  rowId: string;
}
export interface IValidateCandidateResp {
  approved: boolean;
  highestEduLevel: boolean;
  nin: boolean;
  officeEmail: boolean;
  officePhoneNo: boolean;
  personalEmail: boolean;
  personalPhoneNo: boolean;
  rowId: string;
}
